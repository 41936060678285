.list {
    overflow: scroll;
    margin: 1.5rem -1rem 0;
    padding: 0;
    max-height: 22.5rem;
    list-style: none;
}

.item {
    border-bottom: 1px solid var(--color-bg-secondary);
}

.item:first-child {
    border-top: 1px solid var(--color-bg-secondary);
}
