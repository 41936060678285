.img {
  height: 38px;
  width: 133px;
}

@media (min-width: 720px) {
  .img {
    height: 43px;
    width: 144px;
  }
}
