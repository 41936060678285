.footer {
  flex: none;
  position: sticky;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1.5rem;
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
  text-align: left;
}

.copy {
  flex-grow: 1;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-secondary);
}