.container {
  text-align: left;
  display: grid;
  grid-template-columns: 1em auto;
  gap: .7rem;
  align-items: baseline;
}

/* Heavily inspired by https://moderncss.dev/pure-css-custom-checkbox-style/ */
.checkbox {
  appearance: none;
  font: inherit;
  color: currentColor;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0;
  margin-right: 0;
  border: 1px solid var(--color-stroke-deactive);
  border-radius: 6px;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

.checkbox::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: calc(var(--time-transition) / 2) transform ease-in-out;
  box-shadow: inset 1em 1em var(--color-accent);
}

.checkbox:checked::before {
  transform: scale(1);
}

.label {
  font-weight: 600;
  transition: calc(var(--time-transition) / 2) color ease-in-out;
}

.checkbox:checked + .label {
  color: var(--color-accent);
}
