.disclaimer {
  margin: 0 0 1em;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4;
    color: var(--color-secondary);
}

@media (min-width: 720px) {
  .disclaimer {
    font-size: 1rem;
    text-align: left;
  }
}

.disclaimer a {
  color: var(--color-primary);
  text-decoration: none;
}
