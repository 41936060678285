.a {
    display: flex;
    padding: 1rem;
    color: var(--color-primary);
    text-decoration: none;
    text-align: left;
}

.img {
    margin-right: 0.875rem;
    width: 48px;
    height: 48px;
}

.container {
    flex-grow: 1;
}

.title {
    margin: 0;
    font-weight: var(--font-weight-bold);
}

.email {
    margin: 0;
    font-size: 0.875rem;
}

.icon {
    align-self: center;
}
