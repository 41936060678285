:root {
    --color-base-dark: #141c28;
    --color-active-dark: #1a2433;
    --color-passive-light: #ffffff;
    --color-faded-light: transparentize($color: #fff, $amount: .5);
    --color-passive-dark: #3a4759;
    --color-passive-stripped-light: #f2f2f2;
    --color-passive-medium: #e8e9ea;
    --color-passive-medium-mark: #caccd0;
    --color-passive-mark: #969b9f;
    --color-base-brand: #1a1f22;
    --color-faded-brand: #1d4966;
    --color-active-brand: #26b7ff;
    --color-passive-brand: #e9f7fe;
    --color-active-accept: #7dd921;
    --color-active-alert: #ff2638;
    --color-link: #1890ff;
    --color-border: #dddee0;

    --admin-header-height: 12rem;

    --module-size: 8px;
}
