.a {
    display: block;
    margin: 2.5rem auto 0;
    max-width: 18rem;
    padding: 10px 16px;
    font-weight: 600;
    line-height: 1.25;
    text-align: center;
    text-decoration: none;
    color: var(--color-accent-contrast);
    background-color: var(--color-primary);
    border-radius: 12px;
    transition: transform 0.25s ease-in-out;
}

@media (min-width: 720px) {
    .a {
        display: none;
    }
}

.a:hover {
    color: var(--color-accent-contrast);
}

.a:active {
    color: var(--color-accent-contrast);
}

.img {
    vertical-align: -6px;
    margin-right: 10px;
}
