.main {
  flex: auto;
  flex-grow: 1;
  padding-top: 1rem;
}

.container {
  position: relative;
  width: 22rem;
  max-width: calc(100% - 4rem);
  margin: 0 auto;
  text-align: center;
}

.heading {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0 0 1em;
}

.legend {
  display: none;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.4;
  text-align: left;
  color: var(--color-secondary);
  margin: 0 0 1.3em;
}

@media (min-width: 720px) {
  .container {
    width: 28rem;
    margin-left: 8.5rem;
  }

  .heading {
    margin-bottom: 0.375em;
    font-size: 2rem;
    text-align: left;
  }

  .legend {
    display: block;
  }
}
