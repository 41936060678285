.selector {
    position: relative;
}

.checkbox {
    display: none;
}

.img {
    vertical-align: -4px;
    margin-right: 5px;
}

.label {
    padding: 7px 10px 9px 8px;
    line-height: 1;
    font-size: 0.875rem;
    font-weight: var(--font-weight-bold);
    border-radius: 47px;
    color: var(--color-primary);
    background-color: var(--color-bg-secondary);
    text-decoration: none;
    cursor: pointer;
    transition: background-color var(--time-transition);
}

.checkbox:checked + .label {
    background-color: var(--color-bg-tertiary);
}

.list {
    position: absolute;
    bottom: calc(100% + 0.5rem);
    right: -1rem;
    margin: 0;
    padding: 0;
    border: 1px solid var(--color-bg-tertiary);
    border-radius: 12px;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.4;
    list-style: none;
    background: var(--color-bg-primary);
}