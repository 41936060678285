.link {
    display: block;
    position: absolute;
    /* Magic numbers to satisfy design */
    top: 4px;
    padding: 5px 12px 5px 4px;
    line-height: 1;
    font-weight: var(--font-weight-bold);
    border-radius: 47px;
    color: var(--color-primary);
    background-color: var(--color-bg-secondary);
    text-decoration: none;
}

.link:active {
    transform: scale(0.95);
}

.img {
    vertical-align: -7px;
}
