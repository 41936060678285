.heading {
  font-size: 1.4rem;
  line-height: 1;
  font-weight: 700;
  margin: 0 0 0.5em;
}

.text {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4;
}

@media (min-width: 720px) {
  .heading {
    font-size: 2rem;
    text-align: left;
  }

  .text {
    font-size: 1rem;
    text-align: left;
    color: var(--color-secondary);
  }
}
