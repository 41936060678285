.button {
    display: block;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
    border: none;
    border-radius: 12px;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    background-color: var(--color-bg-secondary);
    color: var(--color-primary);
}

.button:active {
    transform: scale(0.95);
}

@media (min-width: 720px) {
    .button {
        padding: 1.5rem 1rem;
    }
}
