.heading {
    font-size: 1.4rem;
    font-weight: 700;
    margin: 0 0 1em;
}

.legend {
    display: none;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.4;
    text-align: left;
    color: var(--color-secondary);
    margin: 0 0 1.3em;
}

@media (min-width: 720px) {
    .heading {
        margin-bottom: 0.375em;
        font-size: 2rem;
        text-align: left;
    }

    .legend {
        display: block;
    }
}
