.container {
    margin-bottom: 1rem;
}

.container.error {
    margin-bottom: 0.5rem;
}

.input {
    height: 4rem;
    width: 100%;
    border: none;
    outline: 1px solid var(--color-stroke-deactive);
    padding: 13px 14px 13px;
    border-radius: 12px;
    vertical-align: -4px;
    color: var(--color-primary);
    background: var(--color-bg-primary);
}

.input:focus {
    outline: 1px solid var(--color-secondary);
}

.input.error {
    outline: 1px solid var(--color-error);
}

/* A reference for the 6-window OTP input */
/* https://dev.to/madsstoumann/using-a-single-input-for-one-time-code-352l */
/* https://chriscoyier.net/2023/12/04/html-css-for-a-one-time-password-input/ */
/*:where([autocomplete=one-time-code]) {*/
/*  --otp-digits: 6;*/
/*  --otp-ls: 2ch;*/
/*  --otp-gap: 1.25;*/

/*  !* private consts *!*/
/*  --_otp-bgsz: calc(var(--otp-ls) + 1ch);*/
/*  --_otp-digit: 0;*/

/*  all: unset;*/
/*  background:*/
/*          linear-gradient(90deg,*/
/*          var(--otp-bg, #BBB) calc(var(--otp-gap) * var(--otp-ls)),*/
/*          transparent 0),*/
/*          linear-gradient(90deg,*/
/*          var(--otp-bg, #EEE) calc(var(--otp-gap) * var(--otp-ls)),*/
/*          transparent 0*/
/*          );*/
/*  background-position: calc(var(--_otp-digit) * var(--_otp-bgsz)) 0, 0 0;*/
/*  background-repeat: no-repeat, repeat-x;*/
/*  background-size: var(--_otp-bgsz) 100%;*/
/*  font-family: ui-monospace, monospace;*/
/*  font-size: var(--otp-fz, 2.5em);*/
/*  inline-size: calc(var(--otp-digits) * var(--_otp-bgsz));*/
/*  letter-spacing: var(--otp-ls);*/
/*  padding-block: var(--otp-pb, 1ch);*/
/*  padding-inline-start: calc(((var(--otp-ls) - 1ch) / 2) * var(--otp-gap));*/
/*}*/
