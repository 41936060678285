.container {
    position: relative;
    width: 22rem;
    max-width: calc(100% - 4rem);
    margin: 0 auto 4rem;
    text-align: center;
}

@media (min-width: 720px) {
    .container {
        display: inline-block;
        width: auto;
        margin-left: calc(8.5rem - 4px);
        text-align: left;
    }

    .container.further {
        background-color: var(--color-bg-secondary);
        border-radius: 18px;
        margin-left: calc(8.5rem - 6.85rem);
        padding: 8px 18px 12px 16px;
    }

    a.further {
        display: inline-block;
        position: relative;
        margin-right: 0.75rem;
        vertical-align: top;
        background-color: var(--color-bg-primary);
    }

    img.further {
        width: 116px;
        height: 34px;
        vertical-align: text-top;
    }
}
