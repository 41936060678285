.item {
    outline: none;
    border-top: 1px solid var(--color-bg-tertiary);
}

.item:first-child {
    border-top: none;
}

.button {
    display: block;
    width: 100%;
    height: 100%;
    padding: 12px 20px 12px 12px;
    border: none;
    background: none;
    font: inherit;
    color: inherit;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
}

.active,
.button:hover {
    background-color: var(--color-bg-tertiary);
}
