.button {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  border: none;
  border-radius: 12px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.25s ease-in-out;
  background-color: var(--color-accent);
  color: var(--color-accent-contrast);
}

@media (min-width: 720px) {
  .button {
    padding: 1.5rem 1rem;
  }
}

.button:active {
  transform: scale(0.95);
}

.button:disabled:active {
  transform: none;
}

.button:disabled {
  background-color: var(--color-accent-disabled);
  cursor: not-allowed;
}
