.container {
  position: relative;
  margin-bottom: 1rem;
}

.container::after {
  pointer-events: none;
  content: attr(data-placeholder);
  position: absolute;
  top: 10px;
  left: 14px;
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 1rem;
  text-align: left;
  color: var(--color-secondary);
  transition: top var(--time-transition),
    font-size var(--time-transition),
    line-height var(--time-transition);
}

.container.error {
    margin-bottom: 0.5rem;
}

/* https://www.bram.us/2023/01/04/css-has-feature-detection-with-supportsselector-you-want-has-not-has/
*/
@supports selector(:has(*)) {
  .container::after {
    top: 0;
    font-size: 1rem;
    line-height: 4rem;
  }

  .container:has(input:focus)::after,
  .container:has(input:not(:placeholder-shown))::after {
    top: 10px;
    font-size: 12px;
    line-height: 1rem;
  }
}

.input {
  height: 4rem;
  width: 100%;
  border: none;
  outline: 1px solid var(--color-stroke-deactive);
  padding: 32px 14px 13px;
  border-radius: 12px;
  vertical-align: -4px;
  color: var(--color-primary);
  background: var(--color-bg-primary);
  transition: outline var(--time-transition);
}

.input:autofill,
.input:-internal-autofill-selected,
.input:-moz-autofill,
.input:-webkit-autofill {
    color: var(--color-primary);
    background: var(--color-bg-primary);
}

@media (min-width: 720px) {
  .container::after,
  .input {
    font-weight: 600;
  }
}

.input:focus {
  outline: 1px solid var(--color-secondary);
}

.input.error {
  outline: 1px solid var(--color-error);
}
