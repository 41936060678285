.form {
  position: relative;
  margin-bottom: 1.5rem;
}

.error {
    margin: 0.5rem 0 0.75rem;
    padding-left: 0.5rem;
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--color-error);
    text-align: left;
}